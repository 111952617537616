<template>
  <v-row>
    <v-col
      v-if="full"
      cols="12"
      sm="6"
      md="6"
    >
      <base-material-stats-card
        color="success"
        icon="mdi-cash"
        title="Comissão acerto"
        :value="String(comissaoAcerto.toFixed(2))"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="6"
    >
      <base-material-stats-card
        color="#9b59b6"
        icon="mdi-account-multiple"
        title="Total de revendedores consignado"
        :value="String(totalDeRevendedoresConsignado)"
      />
    </v-col>
    <v-col
      v-if="full"
      cols="12"
      sm="6"
      md="6"
    >
      <base-material-stats-card
        color="#9b59b6"
        icon="mdi-cash"
        title="Comissão consignado"
        :value="String(comissaoConsignado.toFixed(2))"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="6"
    >
      <base-material-stats-card
        color="#9b59b6"
        icon="mdi-cash"
        title="Total comissão consignado"
        :value="String(totalComissaoConsignado.toFixed(2))"
      />
    </v-col>
    <v-col
      v-if="full"
      cols="12"
      sm="6"
      md="6"
    >
      <base-material-stats-card
        color="#9b59b6"
        icon="mdi-cash"
        title="Comissão cadastros consignados"
        :value="String(comissaoCadastrosConsignados.toFixed(2))"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="6"
    >
      <base-material-stats-card
        color="#3498db"
        icon="mdi-clipboard"
        title="Total de revendedores catálogo"
        :value="String(totalDeRevendedoresCatalogo)"
      />
    </v-col>
    <v-col
      v-if="full"
      cols="12"
      sm="6"
      md="6"
    >
      <base-material-stats-card
        color="#3498db"
        icon="mdi-cash"
        title="Comissão catálogo"
        :value="String(comissaoCatalogo.toFixed(2))"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="6"
    >
      <base-material-stats-card
        color="#3498db"
        icon="mdi-cash"
        title="Total comissão catálogo"
        :value="String(totalComissaoCatalogo.toFixed(2))"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="6"
    >
      <base-material-stats-card
        color="#1abc9c"
        icon="mdi-cash-multiple"
        title="Total à receber"
        :value="String(totalAReceber.toFixed(2))"
      />
    </v-col>
  </v-row>
</template>

<script>
  export default {
    props: {
      full: { type: Boolean, default: () => { return false } },
      totalDeAcertos: { type: Number, default: () => { return 0 } },
      comissaoAcerto: { type: Number, default: () => { return 0 } },
      totalComissaoAcerto: { type: Number, default: () => { return 0 } },
      totalDeRevendedoresConsignado: { type: Number, default: () => { return 0 } },
      comissaoConsignado: { type: Number, default: () => { return 0 } },
      totalComissaoConsignado: { type: Number, default: () => { return 0 } },
      metaDeCadastrosConsignados: { type: Number, default: () => { return 0 } },
      comissaoCadastrosConsignados: { type: Number, default: () => { return 0 } },
      totalComissaoCadastrosConsignados: { type: Number, default: () => { return 0 } },
      totalDeRevendedoresCatalogo: { type: Number, default: () => { return 0 } },
      comissaoCatalogo: { type: Number, default: () => { return 0 } },
      totalComissaoCatalogo: { type: Number, default: () => { return 0 } },
    },

    computed: {
      totalAReceber () {
        return [
          this.totalComissaoAcerto,
          this.totalComissaoConsignado,
          this.totalComissaoCadastrosConsignados,
          this.totalComissaoCatalogo,
        ].reduce((a, b) => a + b, 0)
      },
    },
  }
</script>
