<template>
  <v-simple-table>
    <thead>
      <tr>
        <th
          v-if="paymentMode"
        >
          <v-checkbox
            v-model="allItems"
            :indeterminate="indeterminate"
            @click="changedAllItems"
          />
        </th>
        <th>Id Cliente</th>
        <th>Nome</th>
        <th>Data Cadastro</th>
        <th>Status Cliente</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in items"
        :key="item.id"
      >
        <td
          v-if="paymentMode"
        >
          <v-checkbox
            v-model="item.checked"
            @click="updateCheckbox"
          />
        </td>
        <td>
          <router-link :to="`/promotor/revendedor/${item.cliente.id}`">
            {{ item.cliente.id }}
          </router-link>
        </td>
        <td>{{ item.cliente.nome }}</td>
        <td>{{ item.cliente.dataCadastro | date }}</td>
        <td>{{ String(item.statusCliente).toUpperCase() }}</td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>

  export default {
    props: {
      items: {
        type: Array,
        default: () => { return [] },
      },

      paymentMode: {
        type: Boolean,
        default: () => { return false },
      },
    },

    data: () => ({
      allItems: false,
      indeterminate: false,
    }),

    methods: {
      changedAllItems () {
        this.items.forEach(item => { item.checked = this.allItems })
      },

      updateCheckbox () {
        if (this.items.every(item => { return item.checked === true })) {
          this.indeterminate = false
          this.allItems = true
        } else if (this.items.every(item => { return !item.checked })) {
          this.indeterminate = false
          this.allItems = false
        } else {
          this.indeterminate = true
          this.allItems = false
        }
      },
    },
  }
</script>
